
@use '@angular/material' as mat;
@use 'sass:map';

@mixin search-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $md-search-more-btn: map.get(map.get($theme, background), md-search-more-btn);

    .search {

        .changeview {
            button:disabled {
                .mat-icon {
                    color: mat.m2-get-color-from-palette($accent);
                }
            }
        }

        #showMoreDocumentsButton {
            background-color: $md-search-more-btn !important;
        }

    }
}
