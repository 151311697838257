
@use '@angular/material' as mat;
@use 'sass:map';
@mixin pictureviewer-component-theme($theme)
{
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);

    .picture {
        background: white;
    }

    .versions {
        .border {
            border-right: 1px solid mat.m2-get-color-from-palette($primary, 400);
        }
        .versions-table-list
        {
            display: flex;
            flex-direction: column;
            overflow: auto;
            .version-row
            {
                display: flex;
                &.selected
                {
                    color: mat.m2-get-color-from-palette($accent);
                    .mat-icon {
                        color: mat.m2-get-color-from-palette($accent);
                    }
                }
            }
        }
    }

}
