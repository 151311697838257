
@use '@angular/material' as mat;
@use 'sass:map';
@include mat.elevation-classes();
@include mat.app-background();

@mixin frame-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    $md-appbar-tab-hover:    map.get(map.get($theme, background), md-appbar-tab-hover);
    $md-appbar-tab-active:   map.get(map.get($theme, background), md-appbar-tab-active);
    $md-appbar-tab-inactive: map.get(map.get($theme, foreground), md-appbar-tab-inactive);

    .frame {

        .sidenav {
            .mat-icon {
                color: mat.m2-get-color-from-palette($primary, 900) !important;
            }
            .selected {
                color: mat.m2-get-color-from-palette($accent);
            }
        }

        .appbar {
            background: mat.m2-get-color-from-palette($primary, 200);
            
            .appbar-tabs {
                .tab {
                    color: $md-appbar-tab-inactive;
                    &.hover {
                        background: $md-appbar-tab-hover;
                        border: 1px solid mat.m2-get-color-from-palette($primary, 200);
                    }
                    &.active {
                        color: mat.m2-get-color-from-palette($primary, 900);
                        background: $md-appbar-tab-active;
                    }
                }
            }

            .appbar-icons {
                .mat-icon {
                    color: mat.m2-get-color-from-palette($primary, 700);
                    &:hover {
                        color: mat.m2-get-color-from-palette($primary, 900);
                    }
                    &.active {
                        color: mat.m2-get-color-from-palette($accent) !important;
                        background: $md-appbar-tab-active;
                    }
                }
            }
            
        }

        .content {
            .link-button {
                color: mat.m2-get-color-from-palette($accent)
            }
        }

    }

}
