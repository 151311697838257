
@use '@angular/material' as mat;
@use 'sass:map';
@mixin nutritional-table-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $fg:      map.get($theme, foreground);
    
    $md-task-contents-bg: map.get(map.get($theme, background), md-task-contents-bg);
    $md-task-contents-border: map.get(map.get($theme, background), md-task-contents-border);
    $background: map.get(map.get($theme, background), app-background);

    .nutritional-table {
        border-color: $md-task-contents-border !important;
        .nutritional-table-header {
            border-color: $md-task-contents-border !important;
            background-color: $md-task-contents-bg;
        }
        .nutritional-table-body {
            .table-container {
                .document {
                    background-color: $background;
                    border-color: $md-task-contents-border !important;
                    .actions {
                        border-color: $md-task-contents-border !important;
                        .action {
                            background-color: $md-task-contents-bg;
                        }
                    }
                    .document-image {
                        border-color: $md-task-contents-border !important;
                        background-color: mat.m2-get-color-from-palette($primary, A100);
                        .image-container {
                            background-color: $md-task-contents-border !important;
                        }
                    }
                }
            }
            .details-container {
                .facts-container {
                    background-color: $background;
                    border-color: $md-task-contents-border !important;
                    .actions {
                        border-color: $md-task-contents-border !important;
                        .action {
                            background-color: $md-task-contents-bg;
                            .spectrum-findreplace-icon
                            {
                                background-color: mat.m2-get-color-from-palette($fg, icons);
                            }
                            &:hover {
                                .spectrum-findreplace-icon
                                {
                                    background-color: mat.m2-get-color-from-palette($accent);
                                }
                            }
                        }
                    }
                    .facts-list {
                        .fact {
                            .fact-box {
                                border-color: $md-task-contents-border !important;
                                background-color: mat.m2-get-color-from-palette($primary, A100);
                                &.detail, &.key, &.value {
                                    .text {
                                        color: mat.m2-get-color-from-palette($primary, A100);
                                    }
                                }
                            }
                            .fact-status {
                                &.waiting {
                                    background-color: mat.m2-get-color-from-palette($primary, 100) !important;
                                    color: mat.m2-get-color-from-palette($fg, disabled) !important;

                                }
                                .mat-icon {
                                    width: 14px !important;
                                    height: 14px !important;
                                    font-size: 14px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .nutritional-table-body:hover {
            background-color: $md-task-contents-bg;
        }
    }
}