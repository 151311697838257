
@use '@angular/material' as mat;
@use 'sass:map';
@mixin field-list-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    
    .fieldlist {

        .field {
            &.selected {
                .zoom, .warn, .link, .name, .value {
                    color: mat.m2-get-color-from-palette($accent) !important;
                }
            }
            .warn {
                color: mat.m2-get-color-from-palette($accent);
            }
            .name {
                color: mat.m2-get-color-from-palette($primary, 700);
            }
        }
        
        .footer {
            .status {
                color: mat.m2-get-color-from-palette($primary);
            }
        }
    }
}
