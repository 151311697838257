
@use '@angular/material' as mat;
@use 'sass:map';
@mixin class-selector-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $warning: map.get($theme, warning);
    $background: map.get(map.get($theme, background), md-rules-bg);

    .class-selector {

        .header-documents {

            .selector-name {
                color: mat.m2-get-color-from-palette($primary, 700);
            }   
        
        }    
    }

    .add-button {
        color: mat.m2-get-color-from-palette($primary, 700);
        &:hover
        {
            .add, .add-icon {
                color: mat.m2-get-color-from-palette($accent);
            }
        }

    }

    .class-selector-panel {
        .class-filter-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 700);
    
            .close-header {
                background-color: $background;
                .close-icon {
                    color: mat.m2-get-color-from-palette($primary, 700) !important;
                }
            }
        
            .text-input {
                border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 700);
                .class-search-input {
                    color: mat.m2-get-color-from-palette($primary, 800) !important;
                    background: transparent;
                }
                .actions {
                    .action-icon {
                        color: mat.m2-get-color-from-palette($primary, 800) !important;
                    }
                }
            }
        }
        
        .class-filter-body {

            .menu-firstoptions {
                color: mat.m2-get-color-from-palette($primary, 800);
            }
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
            .option .mat-option-text {
                .mat-icon {
                    // color: $gray-md;
                    &:hover {
                        color: mat.m2-get-color-from-palette($accent);
                    }
                }
            }
    
            .no-results {
                color: mat.m2-get-color-from-palette($primary, 800);
            }
        }
    }
}
