
@use '@angular/material' as mat;
@use 'sass:map';
@mixin accept-legals-component-theme($theme) {
  $primary: map.get($theme, primary);

  .acceptlegals{
    .actions {
      .okButton:disabled {
        background-color: mat.m2-get-color-from-palette($primary) !important;
      }
    }
  }
}
