
@use '@angular/material' as mat;
@use 'sass:map';
@mixin identity-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);


    .identity {
        .identity-header {
            .user {
                .company-role {
                    color: mat.m2-get-color-from-palette($accent);
                }
            }
        }
    }
}