
@use '@angular/material' as mat;
@use 'sass:map';
@mixin document-contents-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    
    $md-task-contents-bg: map.get(map.get($theme, background), md-task-contents-bg);
    $md-task-contents-border: map.get(map.get($theme, background), md-task-contents-border);
    $background: map.get(map.get($theme, background), app-background);

    .document-contents {
        .contents-expansion-panel {
            .expansion-panel-header {
                border-color: $md-task-contents-border !important;
                background-color: $md-task-contents-bg;
            }
            .expansion-panel-body {
                border-color: $md-task-contents-border !important;
                .content-groups {
                    border-color: $md-task-contents-border !important;
                    &:hover {
                        background-color: $md-task-contents-bg; 
                    }
                    .contents-container
                    {
                        .and-or-splitter {
                            &.or {
                                border-color: $md-task-contents-border !important;
                            }
                            &.and {
                                background-color: $md-task-contents-border !important;
                                color: mat.m2-get-color-from-palette($primary, A100) !important;
                            }
                        }
                        .content {
                            border-color: $md-task-contents-border !important;
                            background-color: $background;
                            .actions {
                                border-color: $md-task-contents-border !important;
                                .action {
                                    background-color: $md-task-contents-bg;
                                }
                            }
                            .content-info {
                                .text-content, .image-content {
                                    border-color: $md-task-contents-border !important;
                                    background-color: mat.m2-get-color-from-palette($primary, A100);
                                }
                                .image-content {
                                    .image-container {
                                        background-color: $md-task-contents-border !important;
                                    }
                                }
                                .details {
                                    border-color: $md-task-contents-border !important;
                                    .details-title {
                                        color: $md-task-contents-border !important;
                                    }
                                }
                            }
                        }
                    }

                }
                .content-groups:hover {
                    background-color: $md-task-contents-bg;
                }
            }
        }
    }
}