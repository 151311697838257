
@use '@angular/material' as mat;
@use 'sass:map';
@mixin save-document-popup-component-theme($theme)
{
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .save-document-popup
    {
        .save-option
        {
            .content
            {
                border-top: 1px solid mat.m2-get-color-from-palette($primary, 500);
                .info
                {
                    background-color: mat.m2-get-color-from-palette($primary, 400);
                    color: mat.m2-get-color-from-palette($primary, A100);
                }
            }
        }
    }
}