
@use '@angular/material' as mat;
@use 'sass:map';
@mixin bulk-import-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    
    .bulk-import {

        .files {
            .selectiontext {
                color: mat.m2-get-color-from-palette($accent);
            }
            .status {
                color: mat.m2-get-color-from-palette($primary, 700);
                .file-error {
                    color: red !important;
                }
            }
        }

    }

}
