
@use '@angular/material' as mat;
@use 'sass:map';
@mixin new-version-dialog-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .action:hover {
        color: mat.m2-get-color-from-palette($accent);
        .mat-icon 
        {
            color: mat.m2-get-color-from-palette($accent);
        }
    }
}