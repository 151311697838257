
@use '@angular/material' as mat;
@use 'sass:map';
@mixin register-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);

    .success-message {
        background-color: mat.m2-get-color-from-palette($accent);
        color: #e6e6e6;
       
        .close {
            color: #e6e6e6 !important;
        }
    
        .hint {
        
            .mat-icon {
                color: #4dd5B8 !important;
            }
        }
    
    }


}
