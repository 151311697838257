@use '@angular/material' as mat;
@use 'sass:map';
@include mat.elevation-classes();
@include mat.app-background();

@font-face {
    font-family: 'Adobe Clean';
    src: local('Adobe Clean Regular'), local('Adobe-Clean-Regular'),
        url('./assets/fonts/AdobeClean-Regular.woff2') format('woff2'),
        url('./assets/fonts/AdobeClean-Regular.woff') format('woff'),
        url('./assets/fonts/AdobeClean-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@import 'material-icons/iconfont/material-icons.scss';

$custom-typography: mat.m2-define-typography-config(
    $font-family: 'Adobe Clean'
);
@include mat.all-component-typographies($custom-typography);

@import 'themes/themes.scss';
@import 'app/frame/frame.component.scss-theme';

@import 'app/pages/_shared/field-list/field-list.component.scss-theme';
@import 'app/pages/_shared/tag-list/tag-list.component.scss-theme';
@import 'app/pages/welcome/welcome.component.scss-theme';
@import 'app/pages/work/work-manager/work-manager.component.scss-theme';
@import 'app/pages/work/work.component.scss-theme';
@import 'app/pages/work/work-header/work-header.component.scss-theme';
@import 'app/pages/work/save-document-popup/save-document-popup.component.scss-theme';
@import 'app/pages/_shared/bulk-import/bulk-import.component.scss-theme';
@import 'app/pages/tasks/task-description-popup/task-description-popup.component.scss-theme';
@import 'app/pages/_shared/download-documents/download-documents.component.scss-theme';
@import 'app/pages/_shared/post-save-popup/post-save-popup.component.scss-theme';
@import 'app/pages/_shared/new-version-dialog/new-version-dialog.component.scss-theme';
@import 'app/pages/_shared/document-contents/document-contents.component.scss-theme';
@import 'app/pages/_shared/nutritional-table/nutritional-table.component.scss-theme.scss';
@import 'app/pages/library/library.component.scss-theme';
@import 'app/pages/library/library-card/library-card.component.scss-theme';
@import 'app/pages/_shared/fonts-handler-dialog/fonts-handler-dialog.component.scss-theme.scss';

@import 'app/pages/class-selector/class-selector.component.scss-theme';
@import 'app/pages/search/search.component.scss-theme';

@import 'app/pages/user/register/register.component.scss-theme';
@import 'app/pages/sidenav-content/sidenav-content.component.scss-theme';
@import 'app/pages/user/identity/identity.component.scss-theme';
@import 'app/pages/user/company-selector/company-selector.component.scss-theme';
@import 'app/pages/user/login/login.component.scss-theme';
@import 'app/pages/tasks/tasks-list/tasks-list.component.scss-theme';

@import 'app/pages/rules/rules-dialog/rules-dialog.component.scss-theme.scss';
@import 'app/pages/rules/rule-create/rule-create.component.scss-theme';
@import 'app/pages/rules/rules.component.scss-theme';


@import 'app/pages/picture-viewer/picture-viewer.component.scss-theme';
@import 'app/pages/find-replace/find-replace.component.scss-theme';

@import 'app/pages/search/document-card/document-card.component.scss-theme';

@import 'app/pages/notifications/notification-bell/notification-bell.component.scss-theme';
@import 'app/pages/notifications/new-message/new-message.component.scss-theme';

@import 'app/pages/_shared/tooltip/tooltip.component.scss-theme';
@import 'app/pages/image-zoom/image-zoom.component.scss-theme';

@import 'app/frame/accept-legals/accept-legals.component.scss-theme';

@import 'app/pages/tasks/task-search/task-search.component.scss-theme';
@import 'app/pages/_shared/paste-content-popup/paste-content-popup.component.scss-theme.scss';

@import 'styles-reset.scss';
@import 'styles-reset.scss-theme.scss';

@mixin custom-components-theme($theme) {
    @include frame-component-theme($theme);
    @include search-component-theme($theme);
    @include field-list-component-theme($theme);
    @include tag-list-component-theme($theme);
    @include register-component-theme($theme);
    @include rules-dialog-component-theme($theme);
    @include rule-create-component-theme($theme);
    @include class-selector-component-theme($theme);
    @include welcome-component-theme($theme);
    @include pictureviewer-component-theme($theme);
    @include bulk-import-component-theme($theme);
    @include styles-reset-theme($theme);
    @include sidenav-content-component-theme($theme);
    @include identity-component-theme($theme);
    @include company-selector-component-theme($theme);
    @include tasks-list-component-theme($theme);
    @include find-replace-component-theme($theme);
    @include document-card-component-theme($theme);
    @include notification-bell-component-theme($theme);
    @include new-message-component-theme($theme);
    @include task-description-popup-component-theme($theme);
    @include download-documents-component-theme($theme);
    @include post-save-popup-component-theme($theme);
    @include tooltip-component-theme($theme);
    @include library-component-theme($theme);
    @include library-card-component-theme($theme);
    @include image-zoom-component-theme($theme);
    @include new-version-dialog-component-theme($theme);
    @include work-manager-component-theme($theme);
    @include work-component-theme($theme);
    @include work-header-component-theme($theme);
    @include login-component-theme($theme);
    @include document-contents-component-theme($theme);
    @include nutritional-table-component-theme($theme);
    @include save-document-popup-component-theme($theme);
    @include rules-component-theme($theme);
    @include accept-legals-component-theme($theme);
    @include task-search-component-theme($theme);
    @include fonts-handler-dialog-component-theme($theme);
    @include paste-content-popup-component-theme($theme);
}

.dark-theme {
    @include mat.all-component-themes($illustrator-dark-theme);
    @include custom-components-theme($illustrator-dark-theme);
}

.mediumdark-theme {
    @include mat.all-component-themes($illustrator-mediumdark-theme);
    @include custom-components-theme($illustrator-mediumdark-theme);
}

.mediumlight-theme {
    @include mat.all-component-themes($illustrator-mediumlight-theme);
    @include custom-components-theme($illustrator-mediumlight-theme);
}

.light-theme {
    @include mat.all-component-themes($illustrator-light-theme);
    @include custom-components-theme($illustrator-light-theme);
}

html {

    
        // Botones

        @include mat.button-density(-5);

        // Botones de icono
        @include mat.icon-button-density(-5);

        // Botones de menú
        @include mat.menu-density(-5);

        // Botones de selección
        @include mat.select-density(-5);

        // Botones de radio
        @include mat.radio-density(-5);

        // Botones de casilla de verificación
        @include mat.checkbox-density(-5);

        // Botones de deslizador
        @include mat.slider-density(-5);

        // Botones de entrada
        @include mat.input-density(-5);

        // Botones de lista
        @include mat.list-density(-5);

        // Botones de tabla
        @include mat.table-density(-5);

        // Botones de tarjeta
        @include mat.card-density(-5);

        // Botones de diálogo
        @include mat.dialog-density(-5);

        // Botones de pestañas
        @include mat.tabs-density(-5);

        // Botones de barra de herramientas
        @include mat.toolbar-density(-5);

        // Botones de barra de progreso
        @include mat.progress-bar-density(-5);

        // Botones de spinner
        @include mat.progress-spinner-density(-5);

        // Botones de tooltip
        @include mat.tooltip-density(-5);

        // Botones de snackbar
        @include mat.snack-bar-density(-5);

        // Botones de paginator
        @include mat.paginator-density(-5);

        // Botones de stepper
        @include mat.stepper-density(-5);

        // Botones de badge
        @include mat.badge-density(-5);

        // Botones de chips
        @include mat.chips-density(-5);


        // Botones de tree
        @include mat.tree-density(-5);

        // Botones de sidenav
        @include mat.sidenav-density(-5);

        // Botones de grid list
        @include mat.grid-list-density(-5);

        // Botones de bottom sheet
        @include mat.bottom-sheet-density(-5);

        // Botones de autocomplete
        @include mat.autocomplete-density(-5);

        // Botones de datepicker
        @include mat.datepicker-density(-5);

        // Botones de form field
        @include mat.form-field-density(-5);

        // Botones de slide toggle
        @include mat.slide-toggle-density(-5);

        // Botones de tooltip
        @include mat.tooltip-density(-5);

    width: 100%;
    height: 100%;
    display: flex;
    body {
        width: 100%;
        flex: 1 1 auto;
        font-family: 'Adobe Clean';
        font-size: 12px;
        margin: 0;
    }
}           
