
@use '@angular/material' as mat;
@use 'sass:map';
@mixin task-description-popup-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    
    .task-description {

        .description-header {
            .md-popup-header--title {
                color: mat.m2-get-color-from-palette($accent); 
            }
        }

        .description-content {

            .task-details,
            .task-documents {
                .title {
                    color: mat.m2-get-color-from-palette($primary, 700);
                }
            }
            
            .task-documents {

                .documents {
        
                    .selected-subtask {

                        .line {
                            border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 700);
                            
                            .subtask-name {
                                color: mat.m2-get-color-from-palette($accent); 
                            }   

                        }
                    }
                }
            }

        }


    }
     

}