
@use '@angular/material' as mat;
@use 'sass:map';
@mixin rules-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    $md-rules-border: map.get(map.get($theme, background), md-rules-border);
    $md-rules-bg: map.get(map.get($theme, background), md-rules-bg);
    $md-rules-icon-disabled: map.get(map.get($theme, foreground), md-rules-icon-disabled);

    .rules
    {
        .rules-content
        {
            .rule-group
            {
                border: 1px solid $md-rules-border;

                .header {
                    background-color: $md-rules-bg;
                }
                    
                .rules-list
                {
                    .rule-card
                    {
                        border-top: 1px solid $md-rules-border;
                        .card-header
                        {    
                            .disabled .mat-icon
                            {
                                color: $md-rules-icon-disabled;
                            }
                        }
                        .card-header:hover 
                        {
                            background-color: $md-rules-bg;
                        }
                        .card-body {
                            background-color: $md-rules-bg;
                        }
                    }
                }
            }
            .old-results
            {
                border-top: 1px solid $md-rules-border;
                .refresh-button:hover
                {
                    color: mat.m2-get-color-from-palette($accent);
                }
            }
        }
    }
}
