

@mixin notification-bell-component-theme($theme)
{
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);

    .newmessagebell
    {
      .disconnected
      {
        color: mat.m2-get-color-from-palette($primary, 300) !important;
      }
      .highlighted
      {
          color: mat.m2-get-color-from-palette($accent) !important;
      }
    }
}
