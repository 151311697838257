
@use '@angular/material' as mat;
@use 'sass:map';
@mixin rules-dialog-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    $md-rules-result-bo: map.get(map.get($theme, background), md-rules-result-bo);  
    $md-rules-body-bg: map.get(map.get($theme, background), md-rules-body-bg);
    $md-rules-content-title: map.get(map.get($theme, foreground), md-rules-content-title);

    .rules-popup
    {
        .wrapper
        {
            .scrollcontent
            {
                .rules-content
                {
                    .title {
                        color: $md-rules-content-title;
                    }
                    .rules-list
                    {
                        .card
                        {
                            border-top: 1px solid mat.m2-get-color-from-palette($primary, 800);
                            .header:hover 
                            {
                                background: $md-rules-body-bg;
                            }
                            .body
                            {
                                background: $md-rules-body-bg;
                                .content
                                {
                                    .action:hover {
                                        color: mat.m2-get-color-from-palette($accent);
                                        .mat-icon {
                                            color: mat.m2-get-color-from-palette($accent);
                                        }
                                    }
                                }
                            }
                            .error
                            {
                                color: red;
                            }
                            .warning
                            {
                                color: #f29423;
                            }
                            .ok
                            {
                                color: green;
                            }
                        }
                    }
                }
            }
        }
    }    
}
