
@use '@angular/material' as mat;
@use 'sass:map';
@mixin login-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);


    .login {
        .resetpass {
            color: mat.m2-get-color-from-palette($primary, 900) !important;
        }

        .oauth {
            .text {
                color: mat.m2-get-color-from-palette($primary, 900) !important;
            }
            .actions {
            }
        }
    }

   
}
