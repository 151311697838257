
@use '@angular/material' as mat;
@use 'sass:map';
@mixin work-manager-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $md-relatedtask-icon: map.get(map.get($theme, foreground), md-relatedtask-icon);

    .work-manager
    {
        .subtask
        {
            .description
            {
                .subtask-name
                {
                    &.active
                    {
                        color: mat.m2-get-color-from-palette($accent); 
                    }
                    &.done
                    {
                        color: mat.m2-get-color-from-palette($primary, 500)
                    }
                }    
            }
        }
        .tab-content.tasks {
             
            .task-card {
                background: mat.m2-get-color-from-palette($primary, 800);
                color: mat.m2-get-color-from-palette($primary, 500);
                
                .mat-icon {
                    color: mat.m2-get-color-from-palette($primary, 500) !important;
                }

            }

            .currenttask {
                background: mat.m2-get-color-from-palette($accent);
                color: mat.m2-get-color-from-palette($primary, 900);

                .mat-icon {
                    color: mat.m2-get-color-from-palette($primary, 900) !important;
                }
            }
        }  

        .tab-content.documents, .tab-content.tasks {

            .filename {
                color: mat.m2-get-color-from-palette($primary, 700);
            }
                
            .list {
                .active {
                    color: mat.m2-get-color-from-palette($accent);
                }
                .task {
                    .mat-icon {
                        color: $md-relatedtask-icon;
                    }
                    .taskstatus {
                        color: mat.m2-get-color-from-palette($accent);
                        &:hover {
                            color: mat.m2-get-color-from-palette($accent, 800);
                        }
                    }
                }    
            } 

        }
    }
}
