
@use '@angular/material' as mat;
@use 'sass:map';
@mixin work-header-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    
    $md-doctype-text: map.get(map.get($theme, foreground), md-doctype-text);
    $md-relatedtask-icon: map.get(map.get($theme, foreground), md-relatedtask-icon);
    $md-version-status-bg-draft: map.get(map.get($theme, background), md-version-status-bg-draft);
    $md-version-status-bg-approved: map.get(map.get($theme, background), md-version-status-bg-approved);
    $md-version-text: map.get(map.get($theme, foreground), md-version-text);
    $md-version-status-fg-draft: map.get(map.get($theme, foreground), md-version-status-fg-draft);
    $md-version-status-fg-approved: map.get(map.get($theme, foreground), md-version-status-fg-approved);
    $md-version-status-icon-draft: map.get(map.get($theme, foreground), md-version-status-icon-draft);
    $md-version-status-icon-approved: map.get(map.get($theme, foreground), md-version-status-icon-approved);
    $md-version-status-icon-info: map.get(map.get($theme, foreground), md-version-status-icon-info);

    .header {
        
        .task {
            background-color: #989898;
            color: black !important;
            .mat-icon, button {
                color: black !important;
            } 
            button.add-btn:hover {
                div, .mat-icon {
                    color: mat.m2-get-color-from-palette($accent, 100) !important;
                }    
            }
            .task-header {
                border-bottom: 1px solid #777;
            }
            .task-content {
                .subtask-messages:hover
                {
                    color: mat.m2-get-color-from-palette($accent, 100);
                }
            }
        }

        .document {
            .document-type {
                color: $md-doctype-text;
            }     
            .document-version {
                .version-info {
                    color: $md-version-status-icon-info;
                }  
                .version-text {
                    color: $md-version-text;
                }
                .version-status {  
                    .draft, .preapproved {
                        background-color: $md-version-status-bg-draft;
                        color: $md-version-status-fg-draft;
                        .edit {
                            color: $md-version-status-icon-draft !important;
                        }
                    }
                    .approved {
                        background-color: $md-version-status-bg-approved;
                        color: $md-version-status-fg-approved;
                        .edit {
                            color: $md-version-status-icon-approved !important;
                        }
                    }
                }  
            }
            .check-rules:hover {
                .mat-icon, span {
                    color: mat.m2-get-color-from-palette($accent);
                }
            }
        }
    }
}