

@mixin task-search-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $foregroundColor: map.get(map.get($theme, foreground), base);
    $md-appbar-tab-active: map.get(map.get($theme, background), md-appbar-tab-active);
    $md-task-tag-bg: map.get(map.get($theme, background), md-task-tag-bg);
    .filter-template{
        background-color: $md-appbar-tab-active !important;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-text-infix{
            color: $foregroundColor !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-field-flex{
            color: $foregroundColor !important;
        }
    }
   .archived-tasks{
        .lable-archived-tasks{
            color: $foregroundColor !important;
        }
    }
    .tags {
        border-color: #c5c5c5 !important;
        .tag {
            background-color: $md-task-tag-bg;
            color: #a1a1a1 !important;
            border-color: #a1a1a1 !important;
            .mat-icon {
                color: #a1a1a1 !important;

            }
        }
    }
    
}
