
@use '@angular/material' as mat;
@use 'sass:map';
@mixin document-card-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    $md-version-status-bg-draft: map.get(map.get($theme, background), md-version-status-bg-draft);
    $md-version-status-bg-approved: map.get(map.get($theme, background), md-version-status-bg-approved);
    $md-search-results-list-bg: map.get(map.get($theme, background), md-search-results-list-bg);
    $md-search-results-divider: map.get(map.get($theme, background), md-search-results-divider);
    $md-search-results-info-bg: map.get(map.get($theme, background), md-search-results-info-bg);
    $md-version-status-fg-draft: map.get(map.get($theme, foreground), md-version-status-fg-draft);
    $md-version-status-fg-approved: map.get(map.get($theme, foreground), md-version-status-fg-approved);
    $md-card-names: map.get(map.get($theme, foreground), md-card-names);
    $md-search-results-zoom: map.get(map.get($theme, foreground), md-search-results-zoom);
    $md-search-results-zoom-hover: map.get(map.get($theme, foreground), md-search-results-zoom-hover);
    

    .document-card {
        color: mat.m2-get-color-from-palette($primary, 900);

        .preview {
            background-color: white;
            .overlay {
                background-color: transparent;
                transition: all 300ms ease-in-out; 
                .mat-icon, .icon {
                    opacity: 0;
                    transition-delay: 200ms;
                } 
                &:hover {
                    background-color: black;
                    opacity: .88;
                    .mat-icon, .icon {
                        opacity: 1;
                    } 
                }
                .icon {
                    color: mat.m2-get-color-from-palette($primary, 50);
                    .mat-icon {
                        color: mat.m2-get-color-from-palette($primary, 50) !important;
                    }
                    &:hover {
                        color: mat.m2-get-color-from-palette($accent) !important;
                        .mat-icon {
                            color: mat.m2-get-color-from-palette($accent) !important;
                        }
                    }
                    &.all, &.all .mat-icon {
                        color: mat.m2-get-color-from-palette($accent) !important;
                    }
                }   
            }    
        }

        .preview, .list-view {
            .version-status {
                color: mat.m2-get-color-from-palette($primary, 400);    
                .draft {
                    background-color: $md-version-status-bg-draft;
                    color: $md-version-status-fg-draft;
                }
                .approved {
                    background-color: $md-version-status-bg-approved;
                    color: $md-version-status-fg-approved;
                }
            } 
        }
        
        .data {
            border-top: 1px solid mat.m2-get-color-from-palette($primary, 900);
            .doctype {
                color: mat.m2-get-color-from-palette($accent);
            }
            .names {
                color: $md-card-names;
            }
            .tags {
                color: mat.m2-get-color-from-palette($accent);
                div {
                    background-color: white;
                }
            }
        }

        .list-view {
            background-color: $md-search-results-list-bg;            
            .doctype {
                color: mat.m2-get-color-from-palette($accent);
            }
            .utilities {
                .icon {
                    color: mat.m2-get-color-from-palette($primary, 400);
                    .mat-icon {
                        color: mat.m2-get-color-from-palette($primary, 400) !important;
                    }
                    &:hover {
                        color: mat.m2-get-color-from-palette($accent) !important;
                        .mat-icon {
                            color: mat.m2-get-color-from-palette($accent) !important;
                        }
                    }
                    &.all, &.all .mat-icon {
                        color: mat.m2-get-color-from-palette($accent) !important;
                    }
                }   
                .zoom {
                    color: $md-search-results-zoom;
                    &:hover {
                        color: $md-search-results-zoom-hover;
                    }
                }
                .info {
                    background-color: $md-search-results-info-bg;
                    color: #131313;
                }
            }    
        }

    }

}
