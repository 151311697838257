
@use '@angular/material' as mat;
@use 'sass:map';
@mixin library-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $md-library-card-bg: map.get(map.get($theme, background), md-library-card-bg);

    $list-input-background: map.get(map.get($theme, list), input-background);
    $list-input-text: map.get(map.get($theme, list), input-text);
    $list-general-border: map.get(map.get($theme, list), general-border);
    $list-input-placeholder: map.get(map.get($theme, list), input-placeholder);
    $list-result-info: map.get(map.get($theme, list), result-info);
    $list-divider: map.get(map.get($theme, list), divider);
    $list-button-border: map.get(map.get($theme, list), button-border);
    $list-button-background: map.get(map.get($theme, list), button-background);
    $list-card-text: map.get(map.get($theme, list), card-text);

    .library 
    {
        .action:hover {
            color: mat.m2-get-color-from-palette($accent);
            .mat-icon {
                color: mat.m2-get-color-from-palette($accent);
            }
        }
        .active {
            color: mat.m2-get-color-from-palette($accent);
            .mat-icon {
                color: mat.m2-get-color-from-palette($accent);
            }
        }
        .content {
            .card:hover {
                background-color: $md-library-card-bg;
            }
            .selected {
                background-color: $md-library-card-bg !important;
            }
        }

        .header
        {
            .refresh-button
            {
                border-color: #5f9cf8;
                .refresh-icon
                {
                    color: #5f9cf8 !important;
                }
                .text
                {
                    color: #5f9cf8 !important;
                }
            }
        }
        .body
        {
            .resource-type-selector
            {
                border-bottom: 1px solid $list-divider;
                ::ng-deep .filter-field {
                    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                    &.mat-form-field-appearance-outline 
                    .mat-form-field-outline {
                        background-color: $list-input-background !important;
                        color: $list-input-text !important;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                    &.mat-form-field-appearance-outline 
                    .mat-select-arrow-wrapper {
                        /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                        .mat-select-arrow {
                            color: #2C2C2C !important;
                        }
                    }
                    .mat-mdc-select {
                        /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                        .mat-select-value span, .mat-mdc-option {
                            color: #2C2C2C !important;
                        }
                    }
                }
            }
            .resource-filter
            {
                border-bottom: 1px solid $list-divider;
                .search-box
                {
                    background-color: $list-input-background;
                    border: 1px solid $list-general-border;
                    color: $list-input-text !important;
                    input
                    {
                        background-color: $list-input-background;
                        color: $list-input-text !important;
                    }
                    input::placeholder
                    {
                        //font-style: italic;
                        color: $list-input-placeholder
                    }
                }
                .info
                {
                    .results-count
                    {
                        color: $list-result-info;
                    }
                    .view-selector
                    {
                        .view-button
                        {
                            &.selected
                            {
                                .mat-icon
                                {
                                    color: #5f9cf8;
                                }
                            }
                        }
                    }
                }

            }
        }
        .footer
        {
            border-top: 2px solid $list-divider;
            .upload-button
            {
                background-color: $list-button-background !important;
                border-color: $list-button-border !important;
                .refresh-icon
                {
                    color: $list-card-text !important;
                }
                .text
                {
                    color: $list-card-text !important;
                }
            }
            .upload-button:hover
            {
                .text
                {
                    color: #5f9cf8 !important;
                }
                .mat-icon
                {
                    color: #5f9cf8 !important;
                }
            }
        }
    }
}
