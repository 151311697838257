
@use '@angular/material' as mat;
@use 'sass:map';
@mixin find-replace-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .findreplace {
        color: mat.m2-get-color-from-palette($primary, 900);
        // .mat-input-element {
        //     border-bottom: 1px solid mat.m2-get-color-from-palette($accent) !important;
        // }
    }

}
