
@use '@angular/material' as mat;
@use 'sass:map';
@mixin image-zoom-component-theme($theme)
{
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);

    $md-imagezoom-zoombox: map.get(map.get($theme, background), md-imagezoom-zoombox);
    $md-imagezoom-keyboards-shadow: map.get(map.get($theme, background), md-imagezoom-keyboards-shadow);
    $md-imagezoom-icons: map.get(map.get($theme, foreground), md-imagezoom-icons);
    $md-imagezoom-buttons: map.get(map.get($theme, foreground), md-imagezoom-buttons);
    

    .image-zoom {
      border: 1px solid mat.m2-get-color-from-palette($primary, 900);
      .comment-options {
        background-color: white;
        .mat-icon {
          color: mat.m2-get-color-from-palette($primary, 200);
          &.add-comment:hover {
            color: mat.m2-get-color-from-palette($accent);
          }
          &.toggle-visibility:hover {
            color: mat.m2-get-color-from-palette($primary, 300);
          }
        }
      }
      .comment-input {
        .input-box {
          background-color: #e7e7e7;
          .mat-icon {
            color: mat.m2-get-color-from-palette($primary, 300);
          }  
          textarea {
            background-color: white;
          }
          .save {
            background-color: mat.m2-get-color-from-palette($accent);
            color: white;
            &:hover {
              background-color: mat.m2-get-color-from-palette($accent, 900);
            }
          }
        }  
      }
      .comment-clip-placement {
        color: orange;
      }
      .comment-clip-drawed {
        color: fuchsia;
        &:hover {
          color: blueviolet;
        }
      }
      .editing-comment{
        color: orange !important;
      }
    
      .actions {
        background-color: white;
        .zoom {
          .zoom-box {
            background-color: $md-imagezoom-zoombox;
            .percent {
              background-color: white;
            }
            .mat-icon {
              color: $md-imagezoom-icons;
            }
          }
        }
        .view {
          .mat-icon {
            color: $md-imagezoom-icons;
            &.fit {
              span {
                border: .75px solid $md-imagezoom-icons;
              }
            }
          }
        }
      }
    
      .keyboards {
        background-color: rgba(230,230,230,.7);
        box-shadow: 0.7px 0.7px 5px $md-imagezoom-keyboards-shadow;
        button, .mat-icon {
          color: $md-imagezoom-buttons;
        }
      }
    
    }
    
    .full-screen {
      background: white;
    } 

}
