
@use '@angular/material' as mat;
@use 'sass:map';
@mixin fonts-handler-dialog-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $bg: map.get($theme, background);
    $list-button-border: map.get(map.get($theme, list), button-border);

    .fonts-handler-dialog
    {
        .fonts-handler-body
        {
            .missing-fonts-list {
                border: 1px solid mat.m2-get-color-from-palette($primary, 600);
                .missing-fonts-title {
                    background: mat.m2-get-color-from-palette($bg, dialog);
                }
                .font {
                    .font-name
                    {
                        .name {
                            .text {
                                &.not-found {
                                    color: mat.m2-get-color-from-palette($primary, 700) !important;
                                }
                            }
                        }
                    }
                }
                .font:hover {
                    background-color: mat.m2-get-color-from-palette($bg, background);
                }
            }
            .info {
                .link-button {
                    color: mat.m2-get-color-from-palette($accent)
                }
            }
        }
    }
}


