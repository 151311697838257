

@mixin company-selector-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    $list-input-background: map.get(map.get($theme, list), input-background);
    $list-input-text: map.get(map.get($theme, list), input-text);
    $list-general-border: map.get(map.get($theme, list), general-border);
    $list-input-placeholder: map.get(map.get($theme, list), input-placeholder);
    $md-appbar-tab-inactive: map.get(map.get($theme, foreground), md-appbar-tab-inactive);
    $md-background: map.get(map.get($theme, background), background);

    .company-selector-overlay {
      border: 1px solid $list-general-border;
      background-color: $md-background;
      .search-box {
        background-color: $list-input-background;
        border: 1px solid $list-general-border;
        color: $list-input-text !important;
        input
        {
          background-color: $list-input-background;
          color: $list-input-text !important;
        }
        input::placeholder
        {
          color: $list-input-placeholder
        }
      }
      .companies-list {
        .company-option {
          color: $md-appbar-tab-inactive;
        }
        .company-option:hover {
          background-color: $list-input-background;
        }
      }
    }
}