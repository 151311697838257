
@use '@angular/material' as mat;
@use 'sass:map';
@mixin tag-list-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    .taglist {
        .tags {
            input {
                color: mat.m2-get-color-from-palette($primary);
            }
        }
    }
}
