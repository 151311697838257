
@use '@angular/material' as mat;
@use 'sass:map';
@mixin tasks-list-component-theme($theme) {

    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $md-task-text-gray-medium: map.get(map.get($theme, foreground), md-task-text-gray-medium);
    $md-task-text-gray-light:  map.get(map.get($theme, foreground), md-task-text-gray-light);
    $md-task-text-light:       map.get(map.get($theme, foreground), md-task-text-light);
    $md-task-info-icon:        map.get(map.get($theme, foreground), md-task-info-icon);
    $md-task-bg-light:         map.get(map.get($theme, background), md-task-bg-light);
    $md-task-bg-dark:          map.get(map.get($theme, background), md-task-bg-dark);
    $md-task-showmore-btn:     map.get(map.get($theme, background), md-task-showmore-btn);

    // COMMON
    .tasks-list {

        .task-buttons {
            border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 200);
        }

        .task-buttons, .list-header, .date, .user, .id,
        .subtask .description, .subtask .mat-icon {
            color: $md-task-text-gray-medium !important;
        }

        .subtask {
            .subtask-name {
                color: mat.m2-get-color-from-palette($primary, 900) !important;
                .add-button {
                    color: mat.m2-get-color-from-palette($accent);
                    .mat-icon {
                        color: mat.m2-get-color-from-palette($accent) !important;
                    }
                }
            }
            .description:hover {
                .subtask-name, .subtask-state .mat-icon {
                    color: mat.m2-get-color-from-palette($primary, 900) !important;
                }
            }
        }

        .sorted {
            background-color: $md-task-bg-dark;
        }

        .highlight {
            color: mat.m2-get-color-from-palette($accent);
        }

        .disabled {
          color: mat.m2-get-color-from-palette($primary, 700);
        }

        .assigned{
            .assigned-pill
            {
                background-color: mat.m2-get-color-from-palette($primary, 600);
                color: $md-task-text-light;
            }
        }

        .task {
            .info-icon {
                color: $md-task-info-icon !important;
            }
            .sorted {
                color: $md-task-text-light !important;
                .priority-icon {
                    color: $md-task-text-light !important;
                }
                .task-data > div {
                    color: $md-task-text-gray-medium !important;
                }
                .task-data {
                    border-bottom-color: $md-task-bg-dark !important;
                }
            }
        }

        .subtask {
            .sorted {
                .subtask-name, .subtask-state .mat-icon {
                    color: $md-task-text-gray-medium !important;
                }
            }
        }

        .show-more-btn {
            background-color: mat.m2-get-color-from-palette($primary, 100) !important;
            font-size: 12px !important;
        }

    }
}
