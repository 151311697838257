

@use '@angular/material' as mat;
@use 'sass:map';
@mixin new-message-component-theme($theme)
{
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $bg: map.get($theme, background);
    $md-notifications-popup-bg: map.get(map.get($theme, background), md-notifications-popup-bg);
    $md-notifications-unread-bg: map.get(map.get($theme, background), md-notifications-unread-bg);


    .pushmessages {
        background-color: $md-notifications-popup-bg;
        color: mat.m2-get-color-from-palette($primary, 900);
        .messages {
          .unread {
            background-color: $md-notifications-unread-bg;
          }
          .pushmessage {
            border: 1px solid mat.m2-get-color-from-palette($bg, focused-button);
          }
        }
    }
}
