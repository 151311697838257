
@use '@angular/material' as mat;
@use 'sass:map';
@mixin welcome-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);


    .welcome {
        color: mat.m2-get-color-from-palette($primary, 700);

        .link {
            color: mat.m2-get-color-from-palette($primary, 900);
            &:hover {
                color: mat.m2-get-color-from-palette($accent);
            }
        }
        
    }

}