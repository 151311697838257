
@use '@angular/material' as mat;
@use 'sass:map';
@mixin paste-content-popup-component-theme($theme) {
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .font-selection-panel {
        .font-selection-panel-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 700);
            .text-input {
                .font-search {
                    color: mat.m2-get-color-from-palette($primary, 800) !important;
                    background: transparent;
                }
                .actions {
                    .action-icon {
                        color: mat.m2-get-color-from-palette($primary, 800) !important;
                    }
                }
            }
        }
        .font-selection-panel-body {    
            .no-results {
                color: mat.m2-get-color-from-palette($primary, 800);
            }
        }
    }
}
