
/* CUSTOM
=========== */

.mat-calendar{
    padding: 20px !important;
}
/* Buttons 
----------- */

button {
    background: none;
    border: 0;
    outline: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px;
}

.md-button {
    min-width: 100px;
    height: 25px;
    border-radius: 50px;
    font-family: 'Adobe Clean' !important;
    cursor: pointer;
    text-align: center;
}

.md-textbutton {
    display: flex;
    padding: 0;
    font-family: 'Adobe Clean' !important;
    font-size: 11px;
    line-height: 18px;
    cursor: pointer;
    .mat-icon {
        padding-right: 5px;
    }
}

/* Popups 
---------- */

.md-popup {
    display: flex;
    flex-direction: column;
    max-height: 85vh;
    
    .md-popup-header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 4.75px 20px 3.25px 20px;
        display: flex;
        justify-content: center;
        flex: 0 0 auto;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        & > .mat-icon {
            position: absolute;
            top: 3.5px;
            right: 5px;
            width: 13px !important;
            height: 13px !important;
            font-size: 13px !important;
            font-weight: bold;
            padding: 1.15px 1px 1.25px 1.25px;
            border-radius: 50%;
        }
        .title {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
        }
    }

    .wrapper{
        padding-top: 10px !important;
    }
    .md-popup-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        font-size: 12px;
        overflow: auto;
    }

    .md-popup-actions {
        flex: 0 0 auto;
        margin-top: 10px;;
        padding-top: 0;
        button:first-of-type {
            margin-right: 10px;
        }
        .md-popup-actions--error {
            margin-top: 20px;
            margin-left: 10px;
        }
    }
}

/* Tooltips 
------------- */

.md-tooltipTitle {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.md-tooltipUsers {
    display: flex;
    margin-bottom: 5px;
    span {
        margin: 0 2px;
    }
}

.md-tooltipText {
    max-width: 500px;
    max-height: 50px;
    overflow: hidden;
    text-transform: none;
    white-space: pre-line;
    margin: 10px 0;
}

.md-tooltipMore {
    padding-top: 5px;
    border-top: 1px solid white;
}

/* Spinner 
----------- */

.md-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Errors
----------- */

.md-error {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 7px;
    box-sizing: border-box;
    border-radius: 12.5px;
    span {
        font-size: 11.5px;
        padding-top: .75px;
        padding-right: 5px;
    }
    .mat-icon {
        width: 20px !important;
        height: 20px !important;
        font-size: 20px !important;
        line-height: 20px !important;
    }
}

.md-warning {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 7px;
    box-sizing: border-box;
    border-radius: 12.5px;
    span {
        font-size: 11.5px;
        padding-top: .75px;
        padding-right: 5px;
    }
    .mat-icon {
        width: 18px !important;
        height: 18px !important;
        font-size: 18px !important;
        line-height: 18px !important;
    }
}



/* ANGULAR MATERIAL
==================== */

/* Sidenav 
------------ */

    .mat-drawer-container {
        background: none !important;
        .mat-drawer-content {
            overflow: hidden !important;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .company .mat-mdc-form-text-infix {
        border-top-width: 0em !important;
    }


/* Tabs 
--------- */

    .mat-mdc-tab-header {
        border-bottom: none !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab {
        min-width: 75px !important;
        height: 30px !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab, 
    .mat-mdc-tab-link {
        font-size: 10px !important;
    }  

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab-group, .mat-mdc-tab-body-wrapper {
        flex: 1;
        height: 100%;
    }

    .mat-mdc-tab-body {
        min-height: 50px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab-body.mat-tab-body-active {
        overflow-y: hidden !important;
    }    
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-body-content {
        margin-top: 5px !important;
    }


/* Accordion 
-------------- */

.mat-expansion-panel {
    box-shadow: none !important;
    background: none !important;

    .md-paneltitle, .title {
        width: 100%;
        padding-bottom: 6px;
        display: block;
        font-size: 13px;
    }
}

.mat-expansion-panel-header {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    &:hover {
        background: none !important;
        border: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
    }
}

.mat-expansion-panel,
#mat-expansion-panel-header {
    margin-right: 3px !important;
}

.mat-expansion-panel-header,
.mat-expansion-panel-body {
    padding: 0 6px !important;
    box-shadow: none !important;
}

.mat-expansion-panel-content {
    font-size: 13px !important;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label, .mat-select-panel .mat-mdc-option {
    height: 2em !important;
}

.mat-action-row {
    border: none !important;
}


/* Icons - Buttons 
------------------- */

.material-icons {
    font-size: 16px !important;
}

.mat-icon {
    width: 16px !important;
    height: 16px !important;
    cursor: pointer;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-label {
    display: flex !important;
    flex-direction: row-reverse !important;
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-content {
        font-size: 10px !important;
        padding-right: 10px !important; 
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
        width: 40px !important;
        height: 24px !important;
        border-radius: 15px !important;
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
        .mat-slide-toggle-thumb-container {
            top: 1.5px !important;
            left: 5px !important;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(10px,0,0) !important;
}

.mat-mdc-radio-group {
    .mat-mdc-radio-button {
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-label {
            font-size: 11px !important;
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
            .mat-radio-container {
                width: 12px !important;
                height: 12px !important;
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                .mat-radio-outer-circle,
                .mat-radio-inner-circle {
                    width: 12px !important;
                    height: 12px !important;
                } 
            }
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout 
.mat-checkbox-inner-container {
    transform: scale(.75) !important;
}

.class-selector, .share {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-layout .mat-checkbox-label {
        line-height: 18px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        margin-right: 4px !important;
    }
}

.share {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
        padding-top: 2px !important;
    }
}


/* Popups 
---------- */

.mat-mdc-dialog-container {
    padding: 24px 18px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    position: relative !important;
}

.md-popup mat-card-content {
    margin-bottom: 0 !important;
}

.notificationpopup {
    .taskmessage {
        .time, .date {
            font-size: 9px;
        }
        .message {
            margin: 6px 0;
            font-size: 13px;
            line-height: 16px;
        }
    }    
}


/* Dropdowns 
------------- */

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-panel {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-search-inner .mat-typography 
    .mat-datepicker-content .mat-tab-header 
    .mat-select-search-inner-multiple {
        width: 100% !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-search-clear {
        display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-search-no-entries-found {
        margin-top: 15px;
    }

    .menu-close {
        width: 100%;
        margin-top: 15px;
        overflow: hidden;
        .mat-icon {
            position: relative;
            float: right;
            padding-right: 5px;
        }
    }
}


/* Forms
------------- */

.history {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper {
        width: 100% !important;
        padding-bottom: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-text-infix {
        display: flex !important;
        border: 0 !important;
    }
} 

.mat-mdc-form-field, input {
    border: 0 !important;
    outline: 0 !important;
}

.cdk-overlay-container{
    z-index: 1 !important;
}
.mat-mdc-dialog-surface{
    padding: 14px !important;
}
.rule-create {
    .text-config {
        .mat-mdc-form-field {
            width: 100% !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mdc-line-ripple {
            display: none;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-text-infix {
            padding: 0;
            border: 0;
        }
    }
}


/* Utilities
------------- */

.mat-mdc-tooltip {
    margin: 0px !important;
    font-size: 10px !important;
    line-height: 12px !important;
    line-break: auto !important;
    word-break: break-word !important;
    white-space: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
mat-progress-bar {
    margin-bottom: 3px !important;
}    

::-webkit-scrollbar {
    width: 8px !important;
    max-height: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 15px !important;
}

.as-split-gutter {
    width: 98% !important;
    margin: 10px 0 14.5px 0;
    border-top-width: 2px;
    border-top-style: solid;
    .as-split-gutter-icon {
        background-image: url("./assets/split-image.svg") !important;
    }
}

b {
    // Bold 'Adobe Clean' does not work on mac
    font-family: sans-serif;
}