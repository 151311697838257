
@use '@angular/material' as mat;
@use 'sass:map';
@mixin post-save-popup-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .radio-group-label
    {
        color: mat.m2-get-color-from-palette($primary, 700);
    }
}