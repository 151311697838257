
@use '@angular/material' as mat;
@use 'sass:map';
@mixin work-component-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);

    .no-document {
        .action:hover {
            color: mat.m2-get-color-from-palette($accent);
            .mat-icon 
            {
                color: mat.m2-get-color-from-palette($accent);
            }
        }
    }

    .mat-divider {
        border: 1px solid mat.m2-get-color-from-palette($primary, 200)
    }
}